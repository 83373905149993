
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default Vue.extend({
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        isPreview: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: () => ({}),
        },
        flaggedSections: {
            type: Object,
            default: () => {
                return {
                    license: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    policy: {
                        flagReason: null,
                        isFlagged: false,
                    },
                };
            },
        },
        isRegulatorValid: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            files: "file/getFiles",
        }),
        ...mapState("users", ["users"]),
        mappedSignatoriesPositions(): any {
            const mapped = this.signatoriesPositions.map((signatoryPosition: any) => {
                return {
                    ...signatoryPosition,
                    details: this.getSignature(signatoryPosition.normalizedPosition),
                };
            });
            mapped.forEach(async (signatoryPosition: any) => {
                if (signatoryPosition.details.signatureUploadId) {
                    await this.getFileUrl(signatoryPosition.details.signatureUploadId);
                }
            });
            return mapped;
        },
    },
    data() {
        return {
            signatoriesPositions: [
                // {
                //     position: "Managing Director/Chief Executive officer",
                //     normalizedPosition: "SN1",
                //     description: "",
                //     isRequired: true,
                //     selected: [],
                //     flaggedName: "managingDirector",
                //     flaggedDescription: "View comment on Managing director",
                // },
                // {
                //     position: "Chairman of the Board of Director",
                //     normalizedPosition: "SN2",
                //     description: "",
                //     isRequired: true,
                //     selected: [],
                //     flaggedName: "chairmanOfBoard",
                //     flaggedDescription: "View comment on Chairman of board",
                // },
                {
                    position: "Board Chairman or Chairman, Committee responsible for Governance",
                    normalizedPosition: "SN3",
                    description: "This is the head of the department",
                    isRequired: true,
                    selected: [],
                    flaggedName: "chairmanOfCommittee",
                    flaggedDescription: "View comment on Chairman of committee",
                },
                {
                    position: "Company Secretary or Compliance officer",
                    normalizedPosition: "SN4",
                    description: "",
                    isRequired: true,
                    selected: [],
                    flaggedName: "companySecretary",
                    flaggedDescription: "View comment on company secretary",
                },
            ],
        };
    },
    methods: {
        ...mapActions({
            fetchFile: "file/fetchFile",
        }),
        getUser(id: string) {
            // console.log(id);
            // return this.report?.users?.find((user: any) => user.userId === id);
        },
        async getFileUrl(filename: string): Promise<void> {
            try {
                await this.fetchFile(filename);
            } catch (e) {
                this.$bvToast.toast("", {
                    title: "Unable to fetch file!",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        hasFlaggedReason(signatoryPosition: any) {
            return this.flaggedSections[signatoryPosition?.flaggedName]?.flagReason;
        },
        async fetchCompany() {
            const { id } = this.$route.params;
        },
        getSignatureUrl(signId: string): string {
            const imageObj = this.files[signId];
            return imageObj && imageObj.presignedUrl;
        },
        getSignature(normalizedPosition: string) {
            return this.report?.signatures?.find((signature: any) => signature.normalizedPosition === normalizedPosition) || false;
        },
        sendFlaggedProps(description: string, comment: string) {
            this.$emit("clicked", { description, comment });
        },
    },
    mounted() {
        this.fetchCompany();
    },
});
