
import Vue from "vue";

export default Vue.extend({
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: () => ({}),
        },
        flaggedSections: {
            type: Object,
            default: () => {
                return {
                    // minuteBook: {
                    //     flagReason: null,
                    //     isFlagged: false,
                    // },
                    // memorandum: {
                    //     flagReason: null,
                    //     isFlagged: false,
                    // },
                    attendanceRegister: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    primaryOperatingLicense: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    otherDocuments: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    // proofOfAddress: {
                    //     flagReason: null,
                    //     isFlagged: false,
                    // },

                    // proofOfBusinessLicense: {
                    //     flagReason: null,
                    //     isFlagged: false,
                    // },
                    // certificationOfIncorporation: {
                    //     flagReason: null,
                    //     isFlagged: false,
                    // },
                };
            },
        },
        isRegulatorValid: {
            type: Boolean,
            default: false,
        },
        isPreview: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mapped: {
                // "Proof of registered address": "proofOfAddress",
                "Attendance Register": "attendanceRegister",
                "Primary Operating License": "primaryOperatingLicense",
                "Other documents": "otherDocuments",
                // "Proof of business License": "proofOfBusinessLicense",
                // "Certificate of Incorporation": "certificationOfIncorporation",
                // "Minute Book": "minuteBook",
                // "Memorandum & Articles of Association": "memorandum",
            },
        };
    },
    methods: {
        sendFlaggedProps(description: string, comment: string) {
            this.$emit("clicked", { description, comment });
        },
    },
});
