var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    attrs: {
      "id": `report-confirmation-${_vm.confirmationType}`,
      "size": "md"
    }
  }, [_c('div', {
    staticClass: "report-confirmation d-flex flex-column align-items-center mx-auto justify-content-center text-center"
  }, [_c('div', {
    staticClass: "report-confirmation__content d-flex flex-column"
  }, [_c('BaModalIcon', {
    attrs: {
      "variant": "draft",
      "icon": "info"
    }
  }), _c('h4', {
    staticClass: "mb-0 report-confirmation__title text-black-100"
  }, [_vm.confirmationType === 'resolve-comments' ? [_vm._v("Resolve all comments?")] : _vm.confirmationType === 'submit-report' || _vm.confirmationType === 'approve-report' ? [_vm._v("Are you sure?")] : _vm.confirmationType === 'submit-review' ? [_vm._v("Are you done reviewing?")] : _vm._e()], 2), _c('p', {
    staticClass: "mb-0 report-confirmation__message text-black-60"
  }, [_vm.confirmationType === 'resolve-comments' ? [_vm._v("Are you sure you want to resolve all the comments? The report may be sent back if the reviewer is still unsatisfied with it.")] : _vm.confirmationType === 'submit-report' ? [_vm._v("This report has some unresolved review comments, are you sure you want submit it for review?")] : _vm.confirmationType === 'approve-report' ? [_vm._v("This report has some unresolved review comments, are you sure you want approve it?")] : _vm.confirmationType === 'submit-review' ? [_vm._v("This report would be sent back to the creator with all your comments. They can make any required changes based on the comments and re-submit for review.")] : _vm._e()], 2)], 1), _c('div', {
    staticClass: "report-confirmation__actions d-flex align-items-center justify-content-center w-100"
  }, [_c('BaButton', {
    staticClass: "border-0 text-black-90 bg-gray-40 flex-center",
    attrs: {
      "text": _vm.confirmationType === 'submit-review' ? 'Continue Reviewing' : 'Cancel'
    },
    on: {
      "click": _vm.closeModal
    }
  }), _vm.confirmationType === 'resolve-comments' ? _c('BaButton', {
    staticClass: "border-0 text-black-90 bg-yellow-70 flex-center",
    attrs: {
      "text": "Resolve All Comments",
      "loading": _vm.isLoading,
      "disabled": _vm.isLoading
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('resolve-all-comments');
      }
    }
  }) : _vm._e(), _vm.confirmationType === 'submit-review' ? _c('BaButton', {
    staticClass: "border-0 text-black-90 bg-yellow-70 flex-center",
    attrs: {
      "text": "Send back to Creator",
      "loading": _vm.isLoading,
      "disabled": _vm.isLoading
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('send-back-to-creator');
      }
    }
  }) : _vm._e(), _vm.confirmationType === 'submit-report' ? _c('BaButton', {
    staticClass: "border-0 text-white bg-green-50 flex-center",
    attrs: {
      "text": "Submit for Review",
      "loading": _vm.isLoading,
      "disabled": _vm.isLoading
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('submit-report');
      }
    }
  }) : _vm._e(), _vm.confirmationType === 'approve-report' ? _c('BaButton', {
    staticClass: "border-0 text-white bg-green-50 flex-center",
    attrs: {
      "text": "Approve",
      "loading": _vm.isLoading,
      "disabled": _vm.isLoading
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('approve-report');
      }
    }
  }) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }