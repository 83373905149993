var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container",
    attrs: {
      "id": 'director',
      "title": 'Director',
      "description": `Learn more about this director`,
      "custom-content-class": "overflow-scroll selectable-modal__content"
    }
  }, [_vm.id ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-1"
  }, [_vm._v("First name")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.getUser(_vm.id).firstName))])]), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-1"
  }, [_vm._v("Last name")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.getUser(_vm.id).lastName))])]), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-1"
  }, [_vm._v("Email address")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.getUser(_vm.id).email))])]), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-1"
  }, [_vm._v("Phone number")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.getUser(_vm.id).phoneNumber))])]), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-1"
  }, [_vm._v("Gender")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.getUser(_vm.id).gender === 1 ? 'Male' : 'Female'))])]), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-1"
  }, [_vm._v("Date of appointment")]), _c('p', {
    staticClass: "committee__description"
  })]), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-1"
  }, [_vm._v("Position")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.getUser(_vm.id).position))])])], 1) : _vm._e(), _c('b-row', {
    staticClass: "mt-5"
  }, [_c('b-col', {
    staticClass: "d-flex flex-row justify-content-end align-items-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "text": "Close",
      "variant": "light"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('modal-director');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }