
import Vue from "vue";
import { formatDate } from "@/utils/helpers";

export default Vue.extend({
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: () => ({}),
        },
        flaggedSections: {
            type: Object,
            default: () => {
                return {};
            },
        },
        isRegulatorValid: {
            type: Boolean,
            default: false,
        },
        isPreview: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        formatDate,
        sendFlaggedProps(description: string, comment: string) {
            this.$emit("clicked", { description, comment });
        },
    },
});
