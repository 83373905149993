// export enum ReportDataType {
//     Text = 0,

//     LongText = 1,

//     Number = 2,

//     DatePicker = 3,

//     Media = 4,

//     Dropdown = 5,

//     MultipleDropdown = 6,

//     LinkedType = 7,

//     Bool = 8,

//     BoolAndLongText = 9,

//     BoolAndLongTextAndMedia = 10,

//     LongTextAndMedia = 11,
//     TextAndDatePicker = 12,
//     DropdownAndDatePicker = 13,
//     MultipleDropdownAndLongText = 14,
// }

export default [
    {
        name: "Principle 1",
        title: "Role of the Board",
        description:
            "A successful Company is headed by an effective Board which is responsible for providing entrepreneurial and strategic leadership as well as promoting ethical culture and responsible corporate citizenship. As a link between stakeholders and the Company, the Board is to exercise oversight and control to ensure that management acts in the best interest of the shareholders and other stakeholders while sustaining the prosperity of the Company",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE1",
        isImportant: false,
        questions: [
            {
                question:
                    "Does the Board have an approved Charter which sets out its responsibilities and terms of reference?",
                isRequired: true,
                uniqueName: "P1Q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes, when was it last reviewed?",
                isRequired: true,
                uniqueName: "P1Q2",
                orderIndex: 1,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
        ],
        orderIndex: 1,
    },
    {
        name: "Principle 2",
        title: "Board Structure and Composition",
        description:
            "The effective discharge of the responsibilities of the Board and its committees is assured by an appropriate balance of skills and diversity (including experience and gender) without compromising competence, independence and integrity",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE2",
        isImportant: true,
        questions: [
            {
                question:
                    "What are the qualifications and experiences of the directors? <br/> (You can populate this in a document and attach your response)",
                isRequired: true,
                uniqueName: "P2Q1",
                orderIndex: 1,
                innerOrderIndex: 0,
                dataType: 11,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
            },
            {
                question:
                    "Does the company have a Board-approved diversity policy?",
                isRequired: true,
                uniqueName: "P2Q2",
                orderIndex: 2,
                innerOrderIndex: 1,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
            },
            {
                question:
                    "If yes :<br/> to what extent have the diversity targets been achieved?",
                isRequired: true,
                uniqueName: "P2Q3",
                orderIndex: 2,
                innerOrderIndex: 2,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
            },
            {
                question:
                    "Are there directors holding concurrent directorships?",
                isRequired: true,
                uniqueName: "P2Q4",
                orderIndex: 3,
                innerOrderIndex: 1,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
            },
            {
                question:
                    "If yes:<br /> State names of the directors and the companies.",
                isRequired: true,
                uniqueName: "P2Q5",
                orderIndex: 3,
                innerOrderIndex: 2,
                dataType: 11,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
            },
            {
                question:
                    "Is the MD/CEO or an Executive Director a chair of any Board Committee?",
                isRequired: true,
                uniqueName: "P2Q6",
                innerOrderIndex: 1,
                orderIndex: 4,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
            },
            {
                question: "If yes:<br /> provide the names of the Committees.",
                isRequired: true,
                uniqueName: "P2Q7",
                orderIndex: 4,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
        ],
        orderIndex: 2,
    },
    {
        name: "Principle 3",
        title: "Chairman",
        description:
            "The Chairman is responsible for providing overall leadership of the Company and the Board, and eliciting the constructive participation of all Directors to facilitate effective direction of the Board",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE3",
        isImportant: true,
        questions: [
            {
                question:
                    "Is the Chairman a member or chair of any of the Board Committees?",
                isRequired: true,
                uniqueName: "P3Q1",
                innerOrderIndex: 1,
                orderIndex: 1,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
            },
            {
                question: "If yes<br /> List them",
                isRequired: true,
                uniqueName: "P3Q2",
                orderIndex: 1,
                innerOrderIndex: 2,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
            },
            {
                question:
                    "At which Committee meeting(s) was the Chairman in attendance during the period under review ?",
                isRequired: true,
                uniqueName: "P3Q3",
                orderIndex: 2,
                innerOrderIndex: 0,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
            },
            {
                question: "Is the Chairman an INED or a NED?",
                isRequired: true,
                uniqueName: "P3Q4",
                orderIndex: 3,
                dataType: 5,
                selectOptions: ["INED", "NED"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Is the Chairman a former MD/CEO or ED of the Company?",
                isRequired: true,
                uniqueName: "P3Q5",
                orderIndex: 4,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes <br/> When did his/her tenure as MD end",
                isRequired: true,
                uniqueName: "P3Q6",
                orderIndex: 4,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question: "When was he/she appointed as Chairman?",
                isRequired: true,
                uniqueName: "P3Q7",
                orderIndex: 5,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Are the roles and responsibilities of the Chairman clearly defined?",
                isRequired: true,
                uniqueName: "P3Q8",
                orderIndex: 6,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes: <br /> Specify which document.",
                isRequired: true,
                uniqueName: "P3Q9",
                orderIndex: 6,
                dataType: 11,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
        ],
        orderIndex: 3,
    },
    {
        name: "Principle 4",
        title: "Managing Director/ Chief Executive Officer ",
        description:
            "The Managing Director/Chief Executive Officer is the head of management delegated by the Board to run the affairs of the Company to achieve its strategic objectives for sustainable corporate performance",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE4",
        isImportant: true,
        questions: [
            {
                question:
                    "Does the MD/CEO have a contract of employment which sets out his authority and relationship with the Board?",
                isRequired: true,
                uniqueName: "P4Q1",
                orderIndex: 1,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If no: <br /> in which documents is it specified?",
                isRequired: true,
                uniqueName: "P4Q2",
                orderIndex: 1,
                dataType: 11,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Does the MD/CEO declare any conflict of interest on appointment, annually, thereafter and as they occur?",
                isRequired: true,
                uniqueName: "P4Q3",
                orderIndex: 2,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Which of the Board Committee meetings did the MD/CEO attend during the period under review?",
                isRequired: true,
                uniqueName: "P4Q4",
                orderIndex: 3,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Is the MD/CEO serving as NED in any other company?",
                isRequired: true,
                uniqueName: "P4Q5",
                orderIndex: 4,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes:<br/> Please state the company",
                isRequired: true,
                uniqueName: "P4Q6",
                orderIndex: 4,
                dataType: 0,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Is the membership of the MD/CEO in these companies in line with the Board-approved policies?",
                isRequired: true,
                uniqueName: "P4Q7",
                orderIndex: 5,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 4,
    },
    {
        name: "Principle 5",
        title: "Executive Directors",
        description:
            "Executive Directors support the Managing Director/Chief Executive Officer in the operations and management of the Company",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE5",
        isImportant: true,
        questions: [
            {
                question: "Do the EDs have contracts of employment?",
                isRequired: true,
                uniqueName: "P5Q1",
                orderIndex: 1,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "If yes: <br /> Do the contracts of employment set out the roles and responsibilities of the EDs?",
                isRequired: true,
                uniqueName: "P5Q2",
                orderIndex: 2,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "If no:<br /> In which document are the roles and responsibilities specified?",
                isRequired: true,
                uniqueName: "P5Q3",
                orderIndex: 2,
                dataType: 11,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Do the EDs declare any conflict of interest on appointment, annually, thereafter and as they occur?",
                isRequired: true,
                uniqueName: "P5Q4",
                orderIndex: 3,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            // {
            //     question: "If yes: <br /> please list",
            //     isRequired: true,
            //     uniqueName: "P5Q5",
            //     orderIndex: 4,
            //     dataType: 1,
            //     selectOptions: [],
            //     allowedDocType: [".pdf", ".doc", ".docx"],
            //     isFlaggable: true,
            //     innerOrderIndex: 1,
            // },
            {
                question: "Are there EDs serving as NEDs in any other company?",
                isRequired: true,
                uniqueName: "P5Q5",
                orderIndex: 5,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Are their memberships in these companies in line with Board-approved policy?",
                isRequired: true,
                uniqueName: "P5Q6",
                orderIndex: 6,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 5,
    },
    {
        name: "Principle 6",
        title: "Non-Executive Directors ",
        description:
            "Non-Executive Directors bring to bear their knowledge, expertise and independent judgment on issues of strategy and performance on the Board",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE6",
        isImportant: true,
        questions: [
            {
                question:
                    "Are the roles and responsibilities of the NEDs clearly defined and documented?",
                isRequired: true,
                uniqueName: "P6Q1",
                orderIndex: 1,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes:<br /> Where are these documented?",
                isRequired: true,
                uniqueName: "P6Q2",
                orderIndex: 1,
                dataType: 11,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Do the NEDs have letters of appointment specifying their duties, liabilities and terms of engagement?",
                isRequired: true,
                uniqueName: "P6Q3",
                orderIndex: 2,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Do the NEDs declare any conflict of interest on appointment, annually, thereafter and as they occur?",
                isRequired: true,
                uniqueName: "P6Q4",
                orderIndex: 3,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Are NEDs provided with information relating to the management of the company and on all Board matters?",
                isRequired: true,
                uniqueName: "P6Q5",
                orderIndex: 4,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "If yes: <br/> When is the information provided to the NEDs?",
                isRequired: true,
                uniqueName: "P6Q6",
                orderIndex: 4,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "What is the process of ensuring completeness and adequacy of the information provided?",
                isRequired: true,
                uniqueName: "P6Q7",
                orderIndex: 5,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Do NEDs have unfettered access to the EDs, Company Secretary and the Internal Auditor?",
                isRequired: true,
                uniqueName: "P6Q8",
                orderIndex: 6,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 6,
    },
    {
        name: "Principle 7",
        title: "Independent Non-Executive Directors ",
        description:
            "Independent Non-Executive Directors bring a high degree of objectivity to the Board for sustaining stakeholder trust and confidence",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE7",
        isImportant: true,
        questions: [
            {
                question:
                    "Do the INEDs meet the independence criteria prescribed under Section 7.2 of the Code?",
                isRequired: true,
                uniqueName: "P7Q1",
                orderIndex: 1,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Are there any exceptions?",
                isRequired: true,
                uniqueName: "P7Q2",
                orderIndex: 2,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "What is the process of selecting INEDs?",
                isRequired: true,
                uniqueName: "P7Q3",
                orderIndex: 3,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Do the INEDs have letters of appointment specifying their duties, liabilities and terms of engagement?",
                isRequired: true,
                uniqueName: "P7Q4",
                orderIndex: 4,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Do the INEDs declare any conflict of interest on appointment, annually, thereafter and as they occur?",
                isRequired: true,
                uniqueName: "P7Q5",
                orderIndex: 5,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Does the Board ascertain and confirm the independence of the INEDs?",
                isRequired: true,
                uniqueName: "P7Q6",
                orderIndex: 6,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes, how often?",
                isRequired: true,
                uniqueName: "P7Q7",
                orderIndex: 6,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question: "If yes:<br />What is the process?",
                isRequired: true,
                uniqueName: "P7Q8",
                orderIndex: 6,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question: "Is the INED a Shareholder of the Company? ",
                isRequired: true,
                uniqueName: "P7Q9",
                orderIndex: 7,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "if yes, what is the percentage shareholding?",
                isRequired: true,
                uniqueName: "P7Q10",
                orderIndex: 7,
                dataType: 2,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Does the INED have another relationship with the Company apart from directorship and/or shareholding?",
                isRequired: true,
                uniqueName: "P7Q11",
                orderIndex: 8,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes: <br/> provide details.",
                isRequired: true,
                uniqueName: "P7Q12",
                orderIndex: 8,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question: "What are the components of INEDs remuneration?",
                isRequired: true,
                uniqueName: "P7Q13",
                orderIndex: 9,
                dataType: 11,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 7,
    },
    {
        name: "Principle 8",
        title: "Company Secretary",
        description:
            "The Company Secretary support the effectiveness of the Board by assisting the Board and management to develop good corporate governance practices and culture within the Company",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE8",
        isImportant: false,
        questions: [
            {
                question: "Is the Company Secretary in-house or outsourced?",
                isRequired: true,
                uniqueName: "P8Q1",
                orderIndex: 1,
                dataType: 5,
                selectOptions: ["In-house", "Outsourced"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "What is the qualification and experience of the Company Secretary?",
                isRequired: true,
                uniqueName: "P8Q2",
                orderIndex: 2,
                dataType: 11,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Where the Company Secretary is an employee of the Company, is the person a member of senior management?",
                isRequired: true,
                uniqueName: "P8Q3",
                orderIndex: 3,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Who does the Company Secretary report to?",
                isRequired: true,
                uniqueName: "P8Q4",
                orderIndex: 4,
                dataType: 0,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "What is the appointment and removal process of the Company Secretary?",
                isRequired: true,
                uniqueName: "P8Q5",
                orderIndex: 5,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Who undertakes and approves the performance appraisal of the Company Secretary?",
                isRequired: true,
                uniqueName: "P8Q6",
                orderIndex: 6,
                dataType: 0,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 8,
    },
    {
        name: "Principle 9",
        title: "Access to Independent Advice",
        description:
            "Directors are sometimes required to make decisions of a technical and complex nature that may require independent external expertise",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE9",
        isImportant: false,
        questions: [
            {
                question:
                    "Does the company have a Board-approved policy that allows directors access to independent professional advice in the discharge of their duties?",
                isRequired: true,
                uniqueName: "P9Q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes: <br /> where is it documented?",
                isRequired: true,
                uniqueName: "P9Q2",
                orderIndex: 1,
                dataType: 11,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Who bears the cost for the independent professional advice?",
                isRequired: true,
                uniqueName: "P9Q3",
                orderIndex: 2,
                dataType: 0,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "During the period under review, did the Directors obtain any independent professional advice? ",
                isRequired: true,
                uniqueName: "P9Q4",
                orderIndex: 3,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes:<br /> provide details.",
                isRequired: true,
                uniqueName: "P9Q5",
                orderIndex: 3,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
        ],
        orderIndex: 9,
    },
    {
        name: "Principle 10",
        title: "Meetings of the Board ",
        description:
            "Meetings are the principal vehicle for conducting the business of the Board andsuccessfully fulfilling the strategic objectives of the Company",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE10",
        isImportant: true,
        questions: [
            {
                question:
                    "What is the process for reviewing and approving minutes of Board meetings?",
                isRequired: true,
                uniqueName: "P10Q1",
                orderIndex: 1,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "What are the timelines for sending the minutes to Directors?",
                isRequired: true,
                uniqueName: "P10Q2",
                orderIndex: 2,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "What are the implications for Directors who do not meet the Company policy on meeting attendance?",
                isRequired: true,
                uniqueName: "P10Q3",
                orderIndex: 3,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 10,
    },
    {
        name: "Principle 11",
        title: "Board Committees",
        description:
            "To ensure efficiency and effectiveness, the Board delegates some of its functions, duties and responsibilities to well-structured committees, without abdicating its responsibilities",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE11",
        isImportant: false,
        questions: [
            {
                question:
                    "Do the Board Committees have Board-approved Charters which set out their responsibilities and terms of reference?",
                isRequired: true,
                uniqueName: "P11Q1",
                orderIndex: 1,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "What is the process for reviewing and approving minutes of Board Committee of meetings?",
                isRequired: true,
                uniqueName: "P11Q2",
                orderIndex: 2,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "What are the timelines for sending the minutes to the directors?",
                isRequired: true,
                uniqueName: "P11Q3",
                orderIndex: 3,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Who acts as Secretary to board committees?",
                isRequired: true,
                uniqueName: "P11Q4",
                orderIndex: 4,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "What Board Committees are responsible for the following matters?<br />a. Nomination and Governance<br />b. Remuneration<br />c. Audit<br />d. Risk Management",
                isRequired: true,
                uniqueName: "P11Q5",
                orderIndex: 5,
                dataType: 11,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "What is the process of appointing the chair of each committee ?",
                isRequired: true,
                uniqueName: "P11Q6",
                orderIndex: 6,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Nomination and Governance: </strong> What is the proportion of INEDs to NEDs on the Committee responsible for Nomination and Governance?",
                isRequired: true,
                uniqueName: "P11Q7",
                orderIndex: 7,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "<strong>Committee responsible for Nomination and Governance: </strong> Is the Chairman of the Committee a NED or an INED?",
                isRequired: true,
                uniqueName: "P11Q8",
                orderIndex: 7,
                dataType: 5,
                selectOptions: ["NED", "INED"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "<strong>Committee responsible for Nomination and Governance: </strong> Does the Company have a succession plan policy?",
                isRequired: true,
                uniqueName: "P11Q9",
                orderIndex: 8,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes: <br /> how often is it reviewed?",
                isRequired: true,
                uniqueName: "P11Q10",
                orderIndex: 8,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "<strong>Committee responsible for Nomination and Governance: </strong> How often are Board and Committee charters as well as other governance policies reviewed?",
                isRequired: true,
                uniqueName: "P11Q11",
                orderIndex: 9,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Nomination and Governance: </strong> How does the committee report on its activities to the Board?",
                isRequired: true,
                uniqueName: "P11Q12",
                orderIndex: 10,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Remuneration: </strong> What is the proportion of INEDs to NEDs on the Committee responsible for Remuneration?",
                isRequired: true,
                uniqueName: "P11Q13",
                orderIndex: 11,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "<strong>Committee responsible for Remuneration: </strong> Is the Chairman of the Committee a NED or an INED?",
                isRequired: true,
                uniqueName: "P11Q14",
                orderIndex: 11,
                dataType: 5,
                selectOptions: ["NED", "INED"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "<strong>Committee responsible for Audit: </strong> Does the Company have a Board Audit Committee separate from the Statutory Audit Committee?",
                isRequired: true,
                uniqueName: "P11Q15",
                orderIndex: 12,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Audit: </strong> Are members of the Committee responsible for Audit financially literate?",
                isRequired: true,
                uniqueName: "P11Q16",
                orderIndex: 13,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Audit: </strong> What are their qualifications and experience?",
                isRequired: true,
                uniqueName: "P11Q17",
                orderIndex: 14,
                dataType: 11,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Audit: </strong> Name the financial expert(s) on the Committee responsible for Audit",
                isRequired: true,
                uniqueName: "P11Q18",
                orderIndex: 15,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Audit: </strong> How often does the Committee responsible for Audit review the internal auditor's reports?",
                isRequired: true,
                uniqueName: "P11Q19",
                orderIndex: 16,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Audit: </strong> Does the Company have a Board approved internal control framework in place?",
                isRequired: true,
                uniqueName: "P11Q20",
                orderIndex: 17,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Audit: </strong> How does the Board monitor compliance with the internal control framework?",
                isRequired: true,
                uniqueName: "P11Q21",
                orderIndex: 18,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Audit: </strong> Does the Committee responsible for Audit review the External Auditors management letter, Key Audit Matters and management response to issues raised? Please explain.",
                isRequired: true,
                uniqueName: "P11Q22",
                orderIndex: 19,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Audit: </strong> Is there a Board-approved policy that clearly specifies the non-audit services that the external auditor shall not provide?",
                isRequired: true,
                uniqueName: "P11Q23",
                orderIndex: 20,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Audit: </strong> How many times did the Audit Committee hold discussions with the head of internal audit function and external auditors without the management during the period under review?",
                isRequired: true,
                uniqueName: "P11Q24",
                orderIndex: 21,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Risk Management :</strong> Is the Chairman of the Risk Committee a NED or an INED?",
                isRequired: true,
                uniqueName: "P11Q25",
                orderIndex: 22,
                dataType: 5,
                selectOptions: ["NED", "INED"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "<strong>Committee responsible for Risk Management :</strong> Is there a Board approved Risk Management framework?",
                isRequired: true,
                uniqueName: "P11Q26",
                orderIndex: 23,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes, when was it approved?",
                isRequired: true,
                uniqueName: "P11Q27",
                orderIndex: 23,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "<strong>Committee responsible for Risk Management :</strong> How often does the Committee review the adequacy and effectiveness of the Risk Management Controls in place? Date of last review",
                isRequired: true,
                uniqueName: "P11Q28",
                orderIndex: 24,
                dataType: 12,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Risk Management :</strong> Does the Company have a Board-approved IT Data Governance Framework?",
                isRequired: true,
                uniqueName: "P11Q29",
                orderIndex: 25,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes, how often is it reviewed?",
                isRequired: true,
                uniqueName: "P11Q30",
                orderIndex: 25,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "<strong>Committee responsible for Risk Management :</strong> How often does the Committee receive and review compliance report on the IT Data Governance Framework?",
                isRequired: true,
                uniqueName: "P11Q31",
                orderIndex: 26,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Risk Management :</strong> Is the Chief Risk Officer (CRO) a member of Senior Management and does he have relevant experience for this role?",
                isRequired: true,
                uniqueName: "P11Q32",
                orderIndex: 27,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "<strong>Committee responsible for Risk Management :</strong> How many meetings of the Committee did the CRO attend during the period under review?",
                isRequired: true,
                uniqueName: "P11Q33",
                orderIndex: 28,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 11,
    },
    {
        name: "Principle 12",
        title: "Appointment to the Board",
        description:
            "A written, clearly defined, rigorous, formal and transparent procedure serves as a guide for the selection of Directors to ensure the appointment of high-quality individuals to the Board",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE12",
        isImportant: true,
        questions: [
            {
                question:
                    "Is there a Board-approved policy for the appointment of Directors?",
                isRequired: true,
                uniqueName: "P12Q1",
                orderIndex: 1,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "What criteria are considered for their appointment?",
                isRequired: true,
                uniqueName: "P12Q2",
                orderIndex: 2,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "What is the Board process for ascertaining that prospective directors are fit and proper persons?",
                isRequired: true,
                uniqueName: "P12Q3",
                orderIndex: 3,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Is there a defined tenure for the following:<br/>a. The Chairman<br />b. The MD/CEO<br />c. INED<br />d. NED<br />e. EDs<br />",
                isRequired: true,
                uniqueName: "P12Q4",
                orderIndex: 4,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Please state the tenures",
                isRequired: true,
                uniqueName: "P12Q5",
                orderIndex: 5,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Does the Board have a process to ensure that it is refreshed periodically?",
                isRequired: true,
                uniqueName: "P12Q6",
                orderIndex: 6,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 12,
    },
    {
        name: "Principle 13",
        title: "Induction and Continuing Education",
        description:
            "A formal induction programme on joining the Board as well as regular training assists Directors to effectively discharge their duties to the Company",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE13",
        isImportant: false,
        questions: [
            {
                question:
                    "Does the Board have a formal induction programme for new directors?",
                isRequired: true,
                uniqueName: "P13Q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "During the period under review, were new Directors appointed?",
                isRequired: true,
                uniqueName: "P13Q2",
                orderIndex: 2,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes, Provide date of induction.",
                isRequired: true,
                uniqueName: "P13Q3",
                orderIndex: 2,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Are Directors provided relevant training to enable them effectively discharge their duties?",
                isRequired: true,
                uniqueName: "P13Q4",
                orderIndex: 3,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "If yes, provide training details.",
                isRequired: true,
                uniqueName: "P13Q5",
                orderIndex: 3,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "How do you assess the training needs of Directors?",
                isRequired: true,
                uniqueName: "P13Q6",
                orderIndex: 4,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Is there a Board-approved training plan?",
                isRequired: true,
                uniqueName: "P13Q7",
                orderIndex: 5,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Has it been budgeted for?",
                isRequired: true,
                uniqueName: "P13Q8",
                orderIndex: 6,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 13,
    },
    {
        name: "Principle 14",
        title: "Board Evaluation",
        description:
            "Annual Board evaluation assesses how each Director, the committees of the Board and the Board are committed to their roles, work together and continue to contribute effectively to the achievement of the Company’s objectives",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE14",
        isImportant: false,
        questions: [
            {
                question:
                    "Is there a Board-approved policy for evaluating Board performance?",
                isRequired: true,
                uniqueName: "P14Q1",
                orderIndex: 1,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "For the period under review, was there any Board Evaluation exercise conducted?",
                isRequired: true,
                uniqueName: "P14Q2",
                orderIndex: 2,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "If yes, indicate whether internal or external. Provide date of last evaluation.",
                isRequired: true,
                uniqueName: "P14Q3",
                orderIndex: 2,
                dataType: 13,
                selectOptions: ["Internal", "External"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Has the Board Evaluation report been presented to the full Board?",
                isRequired: true,
                uniqueName: "P14Q4",
                orderIndex: 3,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes, indicate date of presentation.",
                isRequired: true,
                uniqueName: "P14Q5",
                orderIndex: 3,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Did the Chairman discuss the evaluation report with the individual directors?",
                isRequired: true,
                uniqueName: "P14Q6",
                orderIndex: 4,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Is the result of the evaluation for each Director considered in the re-election process? ",
                isRequired: true,
                uniqueName: "P14Q7",
                orderIndex: 5,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 14,
    },
    {
        name: "Principle 15",
        title: "Corporate Governance Evaluation",
        description:
            "Institutionalizing a system for evaluating the Company’s corporate governance practices ensures that its governance standards, practices and processes are adequate and effective",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE15",
        isImportant: false,
        questions: [
            {
                question:
                    "For the period under review, has the Company conducted a corporate governance evaluation?",
                isRequired: true,
                uniqueName: "P15Q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "If yes, provide date of the evaluation.",
                isRequired: true,
                uniqueName: "P15Q2",
                orderIndex: 1,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "Is the result of the Corporate Governance Evaluation presented and considered by the Board?",
                isRequired: true,
                uniqueName: "P15Q3",
                orderIndex: 2,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "If yes, please indicate the date of last presentation",
                isRequired: true,
                uniqueName: "P15Q4",
                orderIndex: 2,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Is the summary of the Corporate Governance Evaluation included in the annual reports and Investors portal?",
                isRequired: true,
                uniqueName: "P15Q5",
                orderIndex: 3,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 15,
    },
    {
        name: "Principle 16",
        title: "Remuneration Governance",
        description:
            "The Board ensures that the Company remunerates fairly, responsibly and transparently so as to promote the achievement of strategic objectives and positive outcomes in the short, medium and long term",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE16",
        isImportant: true,
        questions: [
            {
                question:
                    "Is there a Board-approved Directors’ remuneration policy?",
                isRequired: true,
                uniqueName: "P16Q1",
                orderIndex: 1,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes, how often is it reviewed?",
                isRequired: true,
                uniqueName: "P16Q2",
                orderIndex: 1,
                dataType: 11,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Provide details of directors’ fees, allowances and all other benefits paid to them during the period under review",
                isRequired: true,
                uniqueName: "P16Q3",
                orderIndex: 2,
                dataType: 11,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Is the remuneration of NEDS presented to shareholders for approval?",
                isRequired: true,
                uniqueName: "P16Q4",
                orderIndex: 3,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "If yes, when was it approved?",
                isRequired: true,
                uniqueName: "P16Q5",
                orderIndex: 3,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "What portion of the NEDs remuneration is linked to company performance?",
                isRequired: true,
                uniqueName: "P16Q6",
                orderIndex: 4,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Is there a Board-approved remuneration policy for Executive and Senior management?",
                isRequired: true,
                uniqueName: "P16Q7",
                orderIndex: 5,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "If yes, to what extent is remuneration linked to company performance?",
                isRequired: true,
                uniqueName: "P16Q8",
                orderIndex: 5,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question: "Has the Board set KPIs for Executive Management?",
                isRequired: true,
                uniqueName: "P16Q9",
                orderIndex: 6,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "If yes, was the performance measured against the KPIs?",
                isRequired: true,
                uniqueName: "P16Q10",
                orderIndex: 6,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Do the MD/CEO, EDs and Company Secretary receive a sitting allowance and/or directors fees",
                isRequired: true,
                uniqueName: "P16Q11",
                orderIndex: 7,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Which of the following receive sitting allowance and/or fees:<br />a. MD/CEO<br />b. ED<br />c. Company Secretary<br />d. Other Senior management staff",
                isRequired: true,
                uniqueName: "P16Q12",
                orderIndex: 8,
                dataType: 14,
                selectOptions: [
                    "MD/CEO",
                    "ED",
                    "Company Secretary",
                    "Other Senior Management Staff",
                ],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Is there a Board-approved clawback policy for Executive management?",
                isRequired: true,
                uniqueName: "P16Q13",
                orderIndex: 9,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "If yes, attach the policy.",
                isRequired: true,
                uniqueName: "P16Q14",
                orderIndex: 9,
                dataType: 4,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
        ],
        orderIndex: 16,
    },
    {
        name: "Principle 17",
        title: "Risk Management ",
        description:
            "A sound framework for managing risk and ensuring an effective internal control system is essential for achieving the strategic objectives of the Company",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE17",
        isImportant: false,
        questions: [
            {
                question:
                    "Has the Board defined the company’s risk appetite and limit? ",
                isRequired: true,
                uniqueName: "P17Q1",
                orderIndex: 1,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "How often does the company conduct a risk assessment?",
                isRequired: true,
                uniqueName: "P17Q2",
                orderIndex: 2,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "How often does the board receive and review risk management reports?",
                isRequired: true,
                uniqueName: "P17Q3",
                orderIndex: 3,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 17,
    },
    {
        name: "Principle 18",
        title: "Internal Audit",
        description:
            "An effective internal audit function provides assurance to the Board on the effectiveness of the governance, risk management and internal control systems",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE18",
        isImportant: true,
        questions: [
            {
                question: "Does the company have an Internal Audit function?",
                isRequired: true,
                uniqueName: "P18Q1",
                orderIndex: 1,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "If no: <br /> How has the Board obtained adequate assurance on the effectiveness of internal processes and systems?",
                isRequired: true,
                uniqueName: "P18Q2",
                orderIndex: 1,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Does the company have a Board-approved internal audit charter?",
                isRequired: true,
                uniqueName: "P18Q3",
                orderIndex: 2,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Is the head of internal audit a member of senior management?",
                isRequired: true,
                uniqueName: "P18Q4",
                orderIndex: 3,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "What is the qualification and experience of the head of internal audit?",
                isRequired: true,
                uniqueName: "P18Q5",
                orderIndex: 4,
                dataType: 11,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Does the company have a Board-approved annual risk-based internal audit plan?",
                isRequired: true,
                uniqueName: "P18Q6",
                orderIndex: 5,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Does the head of the internal audit function report at least once every quarter to the committee responsible for audit, on the adequacy and effectiveness of management, governance, risk and control environment; deficiencies observed and management mitigation plans?",
                isRequired: true,
                uniqueName: "P18Q7",
                orderIndex: 6,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Is there an external assessment of the effectiveness of the internal audit function at least once every three years by a qualified independent reviewer appointed by the Board?",
                isRequired: true,
                uniqueName: "P18Q8",
                orderIndex: 7,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes: <br /> When was the last assessment?",
                isRequired: true,
                uniqueName: "P18Q9",
                orderIndex: 7,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Who undertakes and approves the performance evaluation of the Head of Internal Audit?",
                isRequired: true,
                uniqueName: "P18Q10",
                orderIndex: 8,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 18,
    },
    {
        name: "Principle 19",
        title: "Whistleblowing",
        description:
            "An effective whistle-blowing framework for reporting any illegal or unethical behaviour minimises the Company's exposure and prevents recurrence",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE19",
        isImportant: false,
        questions: [
            {
                question:
                    "Does the company have a Board-approved whistleblowing framework?",
                isRequired: true,
                uniqueName: "P19Q1",
                orderIndex: 1,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes: <br/> When was the date of last review",
                isRequired: true,
                uniqueName: "P19Q2",
                orderIndex: 1,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Does the Board ensure that the whistleblowing mechanism and are process reliable, accessible to all stakeholders, guarantees anonymity and protection of the whistleblower?",
                isRequired: true,
                uniqueName: "P19Q3",
                orderIndex: 2,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Is the Audit committee provided with the following reports on a periodic basis? <br /> a. Reported cases<br /> b. Process and results of Investigated cases",
                isRequired: true,
                uniqueName: "P19Q4",
                orderIndex: 3,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 19,
    },
    {
        name: "Principle 20",
        title: "External Audit",
        description:
            "An external auditor is appointed to provide an independent opinion on the true and fair view of the financial statements of the Company to give assurance to stakeholders on the reliability of the financial statements",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE20",
        isImportant: false,
        questions: [
            {
                question:
                    "Who makes the recommendations for the appointment, re-appointment or removal of external auditors?",
                isRequired: true,
                uniqueName: "P20Q1",
                orderIndex: 1,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Who approves the appointment, re-appointment, and removal of External Auditors?",
                isRequired: true,
                uniqueName: "P20Q2",
                orderIndex: 2,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "When was the first date of appointment of the External auditors? ",
                isRequired: true,
                uniqueName: "P20Q3",
                orderIndex: 3,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "How often are the audit partners rotated?",
                isRequired: true,
                uniqueName: "P20Q4",
                orderIndex: 4,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 20,
    },
    {
        name: "Principle 21",
        title: "General Meetings ",
        description:
            "General Meetings are important platforms for the Board to engage shareholders to facilitate greater understanding of the Company’s business, governance and performance. They provide shareholders with an opportunity to exercise their ownership rights and express their views to the Board on any areas of interest",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE21",
        isImportant: false,
        questions: [
            {
                question:
                    "How many days prior to the last general meeting were notices, annual reports and any other relevant information dispatched to Shareholders?",
                isRequired: true,
                uniqueName: "P21Q1",
                orderIndex: 1,
                dataType: 2,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Were the Chairmen of all Board Committees and the Chairman of the Statutory Audit Committee present to respond to Shareholders’ enquiries at the last meeting?",
                isRequired: true,
                uniqueName: "P21Q2",
                orderIndex: 2,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 21,
    },
    {
        name: "Principle 22",
        title: "Shareholder Engagement",
        description:
            "The establishment of a system of regular dialogue with shareholders balance their needs, interests and expectations with the objectives of the Company",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE22",
        isImportant: false,
        questions: [
            {
                question:
                    "Is there a Board-approved policy on shareholders’ engagement?",
                isRequired: true,
                uniqueName: "P22Q1",
                orderIndex: 1,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes: <br />a. when was it last reviewed?<br />",
                isRequired: true,
                uniqueName: "P22Q2",
                orderIndex: 1,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "If yes:<br />b. Is the policy hosted on the company’s website?",
                isRequired: true,
                uniqueName: "P22Q3",
                orderIndex: 1,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "How does the Board engage with Institutional Investors and how often?",
                isRequired: true,
                uniqueName: "P22Q4",
                orderIndex: 2,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 22,
    },
    {
        name: "Principle 23",
        title: "Protection of Shareholder Rights",
        description:
            "Equitable treatment of shareholders and the protection of their statutory and general rights, particularly the interest of minority shareholders, promote good governance",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE23",
        isImportant: true,
        questions: [
            {
                question:
                    "Does the Board ensure that adequate and timely information is provided to the shareholders on the Company’s activities?",
                isRequired: true,
                uniqueName: "P23Q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
        ],
        orderIndex: 23,
    },
    {
        name: "Principle 24",
        title: "Business Conduct and Ethics",
        description:
            "The establishment of professional business and ethical standards underscore the values for the protection and enhancement of the reputation of the Company while promoting good conduct and investor confidence",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE24",
        isImportant: false,
        questions: [
            {
                question:
                    "Does the company have a Board-approved Code of Business Conduct and Ethics (COBE) that guides the professional business and ethical standards?",
                isRequired: true,
                uniqueName: "P24Q1",
                orderIndex: 1,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "If yes: <br />a. Has the COBE been communicated to all internal and external Stakeholders?",
                isRequired: true,
                uniqueName: "P24Q2",
                orderIndex: 1,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "If yes: <br />b. Is the COBE applicable to any or all of the following:<br />1. Board<br />2. Senior management<br />3. Other employees<br />4. Third parties",
                isRequired: true,
                uniqueName: "P24Q3",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question: "When was the date of last review of the policy?",
                isRequired: true,
                uniqueName: "P24Q4",
                orderIndex: 2,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Has the Board incorporated a process for identifying, monitoring and reporting adherence to the COBE?",
                isRequired: true,
                uniqueName: "P24Q5",
                orderIndex: 3,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "What sanctions were imposed for the period under review for non-compliance with the COBE?",
                isRequired: true,
                uniqueName: "P24Q6",
                orderIndex: 4,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
        orderIndex: 24,
    },
    {
        name: "Principle 25",
        title: "Ethical Culture",
        description:
            "The establishment of policies and mechanisms for monitoring insider trading, related party transactions, conflict of interest and other corrupt activities, mitigates the adverse effects of these abuses on the Company and promotes good ethical conduct and investor confidence",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE25",
        isImportant: true,
        questions: [
            {
                question:
                    "Is there a Board-approved policy on insider trading?",
                isRequired: true,
                uniqueName: "P25Q1",
                orderIndex: 1,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes: <br />a. When was the last date of review?",
                isRequired: true,
                uniqueName: "P25Q2",
                orderIndex: 1,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "If yes: <br />b. How does the Board monitor compliance with this policy?",
                isRequired: true,
                uniqueName: "P25Q3",
                orderIndex: 1,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "Does the company have a Board approved policy on related party transactions?",
                isRequired: true,
                uniqueName: "P25Q4",
                orderIndex: 2,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes <br />a. When was the last date of review?",
                isRequired: true,
                uniqueName: "P25Q5",
                orderIndex: 2,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "If yes <br />b. How does the Board monitor compliance with this policy?",
                isRequired: true,
                uniqueName: "P25Q6",
                orderIndex: 2,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "If yes: <br />c. Is the policy applicable to any or all of the following:<br />1. Board<br />2. Senior management<br />3. Other employees (Specify)<br />4. Third parties (Specify)",
                isRequired: true,
                uniqueName: "P25Q7",
                orderIndex: 2,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
            {
                question:
                    "How does the Board ensure adequate disclosure of Related Party Transactions by the responsible parties?",
                isRequired: true,
                uniqueName: "P25Q8",
                orderIndex: 3,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Does the company have a Board approved policy on conflict of interest?",
                isRequired: true,
                uniqueName: "P25Q9",
                orderIndex: 4,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes: <br>a. When was the last date of review?",
                isRequired: true,
                uniqueName: "P25Q10",
                orderIndex: 4,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "If yes: <br>b. How does the Board monitor compliance with this policy?",
                isRequired: true,
                uniqueName: "P25Q11",
                orderIndex: 4,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "If yes: <br />c. Is the policy applicable to any or all of the following:<br/>1. Senior management <br/>2.Other employees(Specify)",
                isRequired: true,
                uniqueName: "P25Q12",
                orderIndex: 4,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
        ],
        orderIndex: 25,
    },
    {
        name: "Principle 26",
        title: "Sustainability",
        description:
            "Paying adequate attention to sustainability issues including environment, social, occupational and community health and safety ensures successful long-term business performance and projects the Company as a responsible corporate citizen contributing to economic development",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE26",
        isImportant: false,
        questions: [
            {
                question: "Is there a Board-approved sustainability policy?",
                isRequired: true,
                uniqueName: "P26Q1",
                orderIndex: 1,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes: <br /> when was it last reviewed?",
                isRequired: true,
                uniqueName: "P26Q2",
                orderIndex: 1,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "How does the Board monitor compliance with the policy?",
                isRequired: true,
                uniqueName: "P26Q3",
                orderIndex: 2,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "How does the Board report compliance with the policy?",
                isRequired: true,
                uniqueName: "P26Q4",
                orderIndex: 3,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Is there a Board-approved policy on diversity in the workplace?",
                isRequired: true,
                uniqueName: "P26Q5",
                orderIndex: 4,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes:<br /> When was it last reviewed?",
                isRequired: true,
                uniqueName: "P26Q6",
                orderIndex: 4,
                dataType: 3,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
        ],
        orderIndex: 26,
    },
    {
        name: "Principle 27",
        title: "Stakeholder Communication",
        description:
            "Communicating and interacting with stakeholders keeps them conversant with the activities of the Company and assists them in making informed decisions",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE27",
        isImportant: false,
        questions: [
            {
                question:
                    "Is there a Board-approved policy on stakeholder management and communication?",
                isRequired: true,
                uniqueName: "P27Q1",
                orderIndex: 1,
                dataType: 10,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Does the Company have an up to date investor relation portal?",
                isRequired: true,
                uniqueName: "P27Q2",
                orderIndex: 2,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "If yes, <br /> provide the link.",
                isRequired: true,
                uniqueName: "P27Q3",
                orderIndex: 0,
                dataType: 0,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
        ],
        orderIndex: 27,
    },
    {
        name: "Principle 28",
        title: "Disclosures",
        description:
            "Full and comprehensive disclosure of all matters material to investors and stakeholders, and of matters set out in this Code, ensures proper monitoring of its implementation which engenders good corporate governance practice",
        hasFee: false,
        fee: 0,
        normalizedName: "PRINCIPLE28",
        isImportant: true,
        questions: [
            {
                question:
                    "Does the company’s annual report include a summary of the corporate governance report?",
                isRequired: true,
                uniqueName: "P28Q1",
                orderIndex: 1,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Has the company been fined by any regulator during the reporting period?",
                isRequired: true,
                uniqueName: "P28Q2",
                orderIndex: 2,
                dataType: 8,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "If yes: <br/> provide details of the fines and penalties.",
                isRequired: true,
                uniqueName: "P28Q3",
                orderIndex: 2,
                dataType: 1,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
        ],
        orderIndex: 28,
    },
];
