
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
    name: "DirectorModal",
    props: {
        id: {
            type: String
        }
    },
    data(): any {
        return {
        };
    },
    computed: {
        ...mapState("reports", ["reportDetails", "boardCommitteeInfo"])
    },
    methods: {
        getUser(id: string) {
            return this.reportDetails?.users?.find((user: any) => user.userId === id);
        }
    },
});
