var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }, [_vm.report ? _c('div', {
    staticClass: "py-3 px-4"
  }, [_c('div', {
    staticClass: "pb-5",
    class: !_vm.isPreview ? 'section__wrapper' : ''
  }, [_c('p', {
    staticClass: "structure-title"
  }, [_vm._v("Execution")]), _vm._l(_vm.mappedSignatoriesPositions, function (signatoryPosition, ix) {
    return _c('div', {
      key: ix,
      staticClass: "structure-table mx-3 mb-4"
    }, [_c('div', {
      staticClass: "structure-table__head d-flex justify-content-between"
    }, [_c('p', [_vm._v(_vm._s(signatoryPosition.position))])]), _c('div', {
      staticClass: "structure-table__body d-flex align-items-center"
    }, [_c('p', {
      staticClass: "structure-table__body--title"
    }, [_vm._v(_vm._s(signatoryPosition.details.fullName || "Not Provided"))]), !_vm.isPreview ? _c('div', [_c('div', {
      staticClass: "mt-2"
    }, [_c('div', {
      staticClass: "p-3 text-center signature-preview-wrapper bg-gray-10 rounded-8 border border-gray-light"
    }, [_c('div', {
      staticClass: "signature-preview d-flex justify-content-center align-items-center",
      style: {
        backgroundImage: `url(${_vm.getSignatureUrl(signatoryPosition.details.signatureUploadId)})`
      }
    }, [!signatoryPosition.details.signatureUploadId ? _c('p', {
      staticClass: "mb-0 sign-text"
    }, [_vm._v("Awaiting Signature")]) : _vm._e()])])])]) : _vm._e(), _vm.hasFlaggedReason(signatoryPosition) && !_vm.isPreview && _vm.isRegulatorValid ? _c('b-button', {
      staticClass: "text-black px-3 py-2",
      staticStyle: {
        "font-size": "11px"
      },
      attrs: {
        "variant": "warning"
      },
      on: {
        "click": function ($event) {
          return _vm.sendFlaggedProps(signatoryPosition.flaggedDescription, _vm.flaggedSections[signatoryPosition.flaggedName].flagReason);
        }
      }
    }, [_vm._v("View Comment")]) : _vm._e()], 1)]);
  })], 2)]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }