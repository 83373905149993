
import Vue from "vue";

export default Vue.extend({
    props: {
        position: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            active: false,
            currentX: 0,
            currentY: 0,
            initialX: 0,
            initialY: 0,
            xOffset: 0,
            yOffset: 0,
        };
    },
    methods: {
        drag(e: TouchEvent | MouseEvent) {
            if (this.active) {
                e.preventDefault();

                if (e.type === "touchmove") {
                    this.currentX = (e as TouchEvent).touches[0].clientX - this.initialX;
                    this.currentY = (e as TouchEvent).touches[0].clientY - this.initialY;
                } else {
                    this.currentX = (e as MouseEvent).clientX - this.initialX;
                    this.currentY = (e as MouseEvent).clientY - this.initialY;
                }

                this.xOffset = this.currentX;
                this.yOffset = this.currentY;

                this.setTranslate(this.currentX, this.currentY, this.$refs.draggable as HTMLDivElement);
            }
        },

        startDragging(e: TouchEvent | MouseEvent) {
            if (e.type === "touchstart") {
                this.initialX = (e as TouchEvent).touches[0].clientX - this.xOffset;
                this.initialY = (e as TouchEvent).touches[0].clientY - this.yOffset;
            } else {
                this.initialX = (e as MouseEvent).clientX - this.xOffset;
                this.initialY = (e as MouseEvent).clientY - this.yOffset;
            }
            this.active = true;
        },

        stopDragging() {
            this.initialX = this.currentX;
            this.initialY = this.currentY;
            this.active = false;
        },

        setTranslate(xPos: number, yPos: number, el: HTMLDivElement) {
            // eslint-disable-next-line no-param-reassign
            if (el) el.style.transform = `translate3d(${xPos}px, ${yPos}px, 0)`;
        },
    },
    mounted() {
        this.$nextTick(() => {
            const body = this.$refs.draggable as HTMLDivElement;
            if (body) {
                body.addEventListener("touchstart", this.startDragging, false);
                body.addEventListener("touchend", this.stopDragging, false);
                body.addEventListener("touchmove", this.drag, false);

                body.addEventListener("mousedown", this.startDragging, false);
                body.addEventListener("mouseup", this.stopDragging, false);
                body.addEventListener("mousemove", this.drag, false);
            }
        });
    },
    destroyed() {
        const body = this.$refs.draggable as HTMLDivElement;
        if (body) {
            body.removeEventListener("touchstart", this.startDragging, false);
            body.removeEventListener("touchend", this.stopDragging, false);
            body.removeEventListener("touchmove", this.drag, false);

            body.removeEventListener("mousedown", this.startDragging, false);
            body.removeEventListener("mouseup", this.stopDragging, false);
            body.removeEventListener("mousemove", this.drag, false);
        }
    },
});
