
import Vue from "vue";
import { formatDate } from "@/utils/helpers";
import { DateTime } from "luxon";

export default Vue.extend({
    props: {
        comment: { type: Object, required: true },
        isEditing: { type: Boolean, required: true },
        canApproveOrDecline: { type: Boolean, required: true },
        isCreator: { type: Boolean, required: true },
        showCommentOptions: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            commentOptions: [
                { icon: "ba-edit-new-gray", iconSize: "14px", text: "Edit Comment", action: () => this.$emit("edit-comment", this.comment.id) },
                { icon: "ba-delete-red", iconSize: "20px", text: "Delete Comment", action: () => this.$emit("delete-comment", this.comment.id) },
            ],
            isResolved: false,
            DateTime,
        };
    },
    methods: {
        formatDate,
    },
});
