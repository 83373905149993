
import Vue from "vue";
import ReportCommentsItem from "@/app/components/reports/ReportCommentsItem.vue";
import ReportConfirmationModal from "@/app/components/modals/ReportConfirmationModal.vue";
import { HOME } from "@/app/routes/endpoints";
import { mapGetters } from "vuex";
import ReportService from "@/api/report-service";

export default Vue.extend({
    components: { ReportCommentsItem, ReportConfirmationModal },
    props: {
        report: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        sectorialRegulator: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    data() {
        const comments: any[] = [];
        return {
            comment: "",
            isShowingComments: false,
            idOfCommentToBeEdited: "",
            selectedCommentId: "",
            comments,
            tabs: [
                { key: "unresolved", value: "Unresolved" },
                { key: "resolved", value: "Resolved" },
            ],
            activeTab: "unresolved",
            isLoading: false,
            isResolvingComments: false,
        };
    },
    computed: {
        ...mapGetters({ profileData: "auth/getUserData" }),
        userIsOnViewReportPage(): boolean {
            return this.$route.name === "reports.view";
        },
        userIsOnCreateReportPage(): boolean {
            return this.$route.name === "reports.create";
        },
        userIsCreatorOfReport(): boolean {
            if (this.userIsOnViewReportPage) {
                return this.report.reportInformation.createdByUser.id === this.profileData.userId;
            }
            return this.report.details.initiatorId === this.profileData.userId;
        },
        userCanApproveOrDenyReport(): boolean {
            if (this.userIsOnViewReportPage) {
                return (
                    (this.report?.reportInformation?.currentDeskUser?.id === this.profileData.userId && this.profileData.roles.includes("ReportReviewer")) ||
                    (this.sectorialRegulator && this.sectorialRegulator?.companyReviewerDetails?.email === this.profileData.email)
                );
            }
            return this.report.details.currentDeskUserId === this.profileData.userId && this.profileData.roles.includes("ReportReviewer");
        },
        filteredCommentsByRegulator(): any {
            return [...this.comments].filter((comment: any) => {
                if (this.sectorialRegulator) return comment.regulatorId === this.sectorialRegulator?.regulatorId;
                return comment;
            });
        },
        filteredComments(): any[] {
            if (this.activeTab === "unresolved") return this.filteredCommentsByRegulator.filter((comment: any) => !comment.resolved);
            return this.filteredCommentsByRegulator.filter((comment: any) => comment.resolved);
        },
        totalNumberOfUnreviewedComments(): number {
            return this.filteredCommentsByRegulator.filter((comment: any) => !comment.resolved).length;
        },
        reportIsInDraft(): boolean {
            return this.report.reportInformation.reportStatus === 0;
        },
        userCanAddComments(): boolean {
            return (
                (this.userCanApproveOrDenyReport && !this.userIsCreatorOfReport) ||
                (this.userCanApproveOrDenyReport && this.sectorialRegulator && ["ngx", "sec", "naicom", "pencom", "cbn"].includes(this.sectorialRegulator.regulatorId))
            );
        },
    },
    methods: {
        toggleIsShowingComments() {
            this.isShowingComments = !this.isShowingComments;
        },
        async createComment() {
            try {
                this.isLoading = true;
                const id = this.$route?.params?.id ?? this.$route?.query?.id;
                const body = { comment: this.comment, ...(this.sectorialRegulator && { regulatorId: this.sectorialRegulator.regulatorId }) };
                const response = await ReportService.createComment({ id, body });
                if (!this.isShowingComments) this.isShowingComments = true;
                this.comments.push({ id: response.data.commentId, resolved: false, createdOn: new Date().toISOString(), body: this.comment });
                this.$nextTick(() => {
                    if (this.$refs.messages) (this.$refs.messages as HTMLUListElement).scrollTop = (this.$refs.messages as HTMLUListElement).scrollHeight;
                });
                this.clearCommentForm();
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
            }
        },
        async updateComment() {
            try {
                this.isLoading = true;
                const body = { comment: this.comment };
                await ReportService.editComment({ commentId: this.idOfCommentToBeEdited, body });
                this.comments = this.comments.map((comment) => {
                    if (comment.id === this.idOfCommentToBeEdited) return { ...comment, body: this.comment };
                    return comment;
                });
                this.clearCommentForm();
                this.isLoading = false;
            } catch {
                this.isLoading = false;
            }
        },
        editComment(commentId: string) {
            this.idOfCommentToBeEdited = commentId;
            const selectedComment = this.comments.find((comment) => comment.id === commentId);
            if (selectedComment) this.comment = selectedComment.body;
            this.selectedCommentId = "";
        },
        async deleteComment(commentId: string) {
            this.comments = this.comments.filter((comment) => comment.id !== commentId);
            await ReportService.deleteComment(commentId);
        },
        async resolveComment(commentId: string) {
            this.comments = this.comments.map((comment: any) => {
                if (comment.id === commentId) return { ...comment, resolved: true };
                return comment;
            });
            this.$bvToast.toast("Comment have been resolved successfully", { title: "Success!", variant: "success", solid: true });
            const haveUnresolvedComments = this.comments.some((comment) => !comment.resolved);
            this.$emit("set-unresolved-comments", haveUnresolvedComments);
            const body = { ids: [commentId] };
            await ReportService.resolveComments(body);
        },
        async unresolveComment(commentId: string) {
            this.comments = this.comments.map((comment: any) => {
                if (comment.id === commentId) return { ...comment, resolved: false };
                return comment;
            });
            const haveUnresolvedComments = this.comments.some((comment) => !comment.resolved);
            this.$emit("set-unresolved-comments", haveUnresolvedComments);
            this.$bvToast.toast("Comment have been unresolved successfully", { title: "Success!", variant: "success", solid: true });
            const body = { ids: [commentId] };
            await ReportService.unresolveComments(body);
        },
        async resolveAllComments() {
            try {
                this.isResolvingComments = true;
                const ids = this.comments.filter((comment) => !comment.resolved).map(({ id }) => id);
                const body = { ids };
                await ReportService.resolveComments(body);
                this.comments = this.comments.map((comment) => {
                    if (ids.includes(comment.id)) return { ...comment, resolved: true };
                    return comment;
                });
                this.isResolvingComments = false;
                this.$bvModal.hide("modal-report-confirmation-resolve-comments");
                this.$bvToast.toast("All comments have been resolved successfully", { title: "Success!", variant: "success", solid: true });
                this.$emit("set-unresolved-comments", false);
            } catch (error) {
                this.isResolvingComments = false;
            }
        },
        openCommentOptions(commentId: string) {
            if (commentId === this.selectedCommentId) {
                this.selectedCommentId = "";
                return;
            }
            this.selectedCommentId = commentId;
        },
        clearCommentForm() {
            this.idOfCommentToBeEdited = "";
            this.comment = "";
        },
        continueEditingReport() {
            const { id } = this.$route.params;
            this.$router.push({ name: HOME.CREATE_REPORT.NAME, query: { id: id as string } });
        },
    },
    mounted() {
        this.comments = this.report.comments.map(
            ({ id, comments, resolved, userId, createdOn, regulatorId }: { id: string; comments: string; resolved: boolean; userId: string; createdOn: string; regulatorId: string }) => {
                return { id, body: comments, userId, resolved, createdOn, ...(regulatorId && { regulatorId }) };
            },
        );
        this.isShowingComments = this.comments.length > 0;
        if (this.isShowingComments) this.activeTab = "resolved";
    },
});
