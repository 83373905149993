
import Vue from "vue";
import { mapState } from "vuex";
import DirectorModal from "@/app/components/modals/DirectorModal.vue"

export default Vue.extend({
    name: "BoardCommitteeModal",
    components: {
        DirectorModal
    },
    props: {
    },
    data(): any {
        return {
            userId: ""
        };
    },
    computed: {
        ...mapState("reports", ["reportDetails", "boardCommitteeInfo"])
    },
    methods: {
        getUser(id: string) {
            return this.reportDetails?.users?.find((user: any) => user.userId === id);
        },
        getCommitteeDetails(userId: any) {
            this.$bvModal.show("modal-director")
            this.userId = userId
        }
    },
});
