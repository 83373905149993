var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }, [_c('div', {
    staticClass: "py-3 px-4"
  }, [_c('div', {
    staticClass: "pb-5",
    class: !_vm.isPreview ? 'section__wrapper' : ''
  }, [_c('p', {
    staticClass: "structure-title"
  }, [_vm._v("Governance structures")]), _vm._l(_vm.report.structure.commitees, function (committee, ix) {
    return _c('div', {
      key: ix + 10,
      staticClass: "structure-table mx-3 mb-4"
    }, [_c('div', {
      staticClass: "structure-table__head d-flex justify-content-between"
    }, [_c('p', [_vm._v("Board committee(s)")]), _c('p', {
      staticStyle: {
        "width": "170px"
      }
    }, [_vm._v("Committee Members")]), _c('p', {
      staticStyle: {
        "width": "100px"
      }
    }, [_vm._v("Position")])]), _c('div', {
      staticClass: "structure-table__body"
    }, [_c('p', {
      staticClass: "structure-table__body--title"
    }, [_vm._v(_vm._s(committee.name))]), _c('div', {
      staticClass: "text-center"
    }, _vm._l(committee.userIds, function (user, indx) {
      return _c('div', {
        key: indx,
        staticClass: "text-black text-sm-3 mt-1"
      }, [_c('p', [_vm._v(_vm._s(_vm.getUser(user).firstName) + " " + _vm._s(_vm.getUser(user).lastName))])]);
    }), 0), _c('div', {
      staticClass: "text-center"
    }, _vm._l(committee.userIds, function (user, indx) {
      return _c('div', {
        key: indx,
        staticClass: "text-black text-sm-3 mt-1"
      }, [_c('span', {
        staticClass: "blue-pill ml-2"
      }, [_vm._v(_vm._s(_vm.getUser(user).position))])]);
    }), 0)]), _vm.report.structure.commitees.length === 0 ? _c('div', {
      staticClass: "structure-table__body--title text-black-50 text-center px-3 py-3"
    }, [_c('em', [_vm._v("None added")])]) : _vm._e()]);
  }), _vm._l(_vm.mappedStructurePositions, function (office, ix) {
    return _c('div', {
      key: ix,
      staticClass: "structure-table mx-3 mb-4"
    }, [_c('div', {
      staticClass: "d-flex justify-content-between structure-table__head"
    }, [_c('p', [_vm._v(_vm._s(office.positionName))]), _c('p', {
      staticStyle: {
        "width": "120px"
      }
    }, [_vm._v("Position")])]), office.userIds.length > 0 ? _c('div', _vm._l(office.userIds, function (user, ix) {
      return _c('div', {
        key: ix,
        staticClass: "structure-table__body"
      }, [_c('p', {
        staticClass: "structure-table__body--title"
      }, [_vm._v(_vm._s(_vm.getUser(user).firstName) + " " + _vm._s(_vm.getUser(user).lastName))]), _c('div', {
        staticStyle: {
          "width": "150px"
        }
      }, [_c('span', {
        staticClass: "blue-pill"
      }, [_vm._v(_vm._s(_vm.getUser(user).position))])]), _vm.flaggedSections[office.flaggedName].flagReason && _vm.isRegulatorValid ? _c('b-button', {
        staticClass: "text-black px-3 py-2 ml-2",
        staticStyle: {
          "font-size": "11px"
        },
        attrs: {
          "variant": "warning"
        },
        on: {
          "click": function ($event) {
            return _vm.sendFlaggedProps(office.flaggedDescription, _vm.flaggedSections[office.flaggedName].flagReason);
          }
        }
      }, [_vm._v("View Comment")]) : _vm._e()], 1);
    }), 0) : _c('div', {
      staticClass: "structure-table__body--title text-black-50 px-3 py-3"
    }, [_c('em', [_vm._v("None added")])])]);
  })], 2)]), _c('board-committee-modal')], 1), _c('director-modal', {
    attrs: {
      "id": _vm.userId
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }