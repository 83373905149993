var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }, [_c('div', {
    staticClass: "py-3 px-4"
  }, [_c('div', {
    staticClass: "pb-5",
    class: !_vm.isPreview ? 'section__wrapper' : ''
  }, [_c('p', {
    staticClass: "structure-title"
  }, [_vm._v("Documentation")]), _vm._l(_vm.report.documents, function (document, ix) {
    return _c('div', {
      key: ix,
      staticClass: "mx-3 mb-4"
    }, [_c('p', {
      staticClass: "document-title mb-1"
    }, [_vm._v(_vm._s(document.name))]), document.attatchments.length === 0 ? _c('div', {
      staticClass: "not-provided text-black-50"
    }, [_c('em', [_vm._v(" No Document Provided")])]) : _c('div', _vm._l(document.attatchments, function (attachment, idx) {
      return _c('ba-file-input', {
        key: idx,
        attrs: {
          "noLabel": "",
          "noDivider": "",
          "readonly": "",
          "disabled": "",
          "fileName": attachment.fileName,
          "fileId": attachment.docUploadId,
          "size": attachment.size
        }
      });
    }), 1), _vm.flaggedSections[_vm.mapped[document.name]].flagReason && _vm.isRegulatorValid ? _c('b-button', {
      staticClass: "text-black px-3 py-2",
      staticStyle: {
        "font-size": "11px"
      },
      attrs: {
        "variant": "warning"
      },
      on: {
        "click": function ($event) {
          return _vm.sendFlaggedProps('View comment on document', _vm.flaggedSections[_vm.mapped[document.name]].flagReason);
        }
      }
    }, [_vm._v("View Comment")]) : _vm._e()], 1);
  })], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }