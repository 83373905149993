var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticClass: "comments bg-gray-10 overflow-hidden"
  }, [_c('div', {
    staticClass: "comments__header bg-gray-20 d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "comments__header--left"
  }, [_c('h6', {
    staticClass: "text-black-100 d-flex align-items-center mb-0"
  }, [_vm._v(" Review Comments "), _c('span', {
    staticClass: "comments__count"
  }, [_c('b-badge', {
    class: _vm.totalNumberOfUnreviewedComments === 0 ? 'bg-black-10 text-black-70' : 'bg-red-80 text-white',
    attrs: {
      "pill": ""
    }
  }, [_vm._v(_vm._s(_vm.totalNumberOfUnreviewedComments))])], 1)])]), _c('div', {
    staticClass: "comments__header--right d-flex align-items-center"
  }, [_vm.userCanApproveOrDenyReport && _vm.filteredCommentsByRegulator.length > 0 && !_vm.userIsCreatorOfReport ? _c('BaButton', {
    staticClass: "border-0 text-black-90 bg-yellow-70 done",
    attrs: {
      "padding": "small",
      "text": "Done"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$emit('show-review-confirmation');
      }
    }
  }) : _vm._e(), _vm.userIsOnViewReportPage && _vm.userIsCreatorOfReport && _vm.reportIsInDraft ? _c('button', {
    staticClass: "bg-transparent m-0 text-blue-90 comments__header__button d-flex align-items-center",
    on: {
      "click": _vm.continueEditingReport
    }
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-edit-pen",
      "height": "auto",
      "width": "auto"
    }
  }), _vm._v(" Edit Report ")], 1) : _vm._e(), _vm.userIsOnCreateReportPage && _vm.userIsCreatorOfReport && _vm.totalNumberOfUnreviewedComments > 0 ? _c('button', {
    staticClass: "bg-transparent m-0 text-blue-90 comments__header__button d-flex align-items-center",
    on: {
      "click": function ($event) {
        return _vm.$bvModal.show('modal-report-confirmation-resolve-comments');
      }
    }
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-check",
      "height": "auto",
      "width": "auto"
    }
  }), _vm._v(" Resolve All ")], 1) : _vm._e(), _c('button', {
    staticClass: "bg-transparent toggler border-0 m-0 p-0",
    on: {
      "click": _vm.toggleIsShowingComments
    }
  }, [_vm.isShowingComments ? _c('ba-base-icon', {
    attrs: {
      "name": "ba-arrow-down-double",
      "height": "20px",
      "width": "20px"
    }
  }) : _c('ba-base-icon', {
    attrs: {
      "name": "ba-arrow-up-double",
      "height": "20px",
      "width": "20px"
    }
  })], 1)], 1)]), _vm.isShowingComments && _vm.filteredCommentsByRegulator.length > 0 ? [_c('div', {
    staticClass: "comments__tab"
  }, [_c('BaTabs', {
    attrs: {
      "tabs": _vm.tabs,
      "active-tab": _vm.activeTab,
      "have-initial-margin": true
    },
    on: {
      "set-active-tab": function ($event) {
        _vm.activeTab = $event;
      }
    }
  })], 1), _c('div', {
    ref: "messages",
    staticClass: "comments__body"
  }, [_c('ul', {
    staticClass: "mb-0 list-unstyled"
  }, _vm._l(_vm.filteredComments, function (comment) {
    return _c('ReportCommentsItem', {
      key: comment.id,
      attrs: {
        "comment": comment,
        "is-editing": _vm.idOfCommentToBeEdited === comment.id,
        "show-comment-options": _vm.selectedCommentId === comment.id,
        "can-approve-or-decline": _vm.userCanApproveOrDenyReport,
        "is-creator": _vm.userIsCreatorOfReport
      },
      on: {
        "edit-comment": _vm.editComment,
        "delete-comment": _vm.deleteComment,
        "resolve-comment": _vm.resolveComment,
        "unresolve-comment": _vm.unresolveComment,
        "open-comment-options": _vm.openCommentOptions,
        "close-options": function ($event) {
          _vm.selectedCommentId = '';
        }
      }
    });
  }), 1)])] : _vm._e(), _vm.userCanAddComments ? _c('div', {
    staticClass: "comments__form"
  }, [_c('div', {
    staticClass: "bg-white"
  }, [_c('div', {
    staticClass: "comments__form-body"
  }, [_c('ba-textarea', {
    staticClass: "text-black-100",
    attrs: {
      "placeholder": "Type comment...",
      "no-resize": "",
      "usage": "message"
    },
    model: {
      value: _vm.comment,
      callback: function ($$v) {
        _vm.comment = $$v;
      },
      expression: "comment"
    }
  })], 1), _c('div', {
    staticClass: "comments__form-actions d-flex justify-content-end"
  }, [!!_vm.idOfCommentToBeEdited ? [_c('BaButton', {
    staticClass: "text-white border-0 cancel bg-white d-flex justify-content-center align-items-center",
    attrs: {
      "padding": "icon",
      "icon": "ba-cancel-black"
    },
    on: {
      "click": _vm.clearCommentForm
    }
  }), _c('BaButton', {
    staticClass: "text-white border-0 bg-green-50 d-flex justify-content-center align-items-center",
    attrs: {
      "padding": "icon",
      "icon": "ba-check-thin-white",
      "disabled": !_vm.comment || _vm.isLoading,
      "loading": _vm.isLoading
    },
    on: {
      "click": _vm.updateComment
    }
  })] : [_c('BaButton', {
    staticClass: "text-white border-0 bg-black-100 d-flex justify-content-center align-items-center",
    attrs: {
      "text": "Save comment",
      "disabled": !_vm.comment || _vm.isLoading,
      "loading": _vm.isLoading
    },
    on: {
      "click": _vm.createComment
    }
  })]], 2)])]) : _vm._e()], 2), _c('ReportConfirmationModal', {
    attrs: {
      "is-loading": _vm.isResolvingComments,
      "confirmation-type": "resolve-comments"
    },
    on: {
      "resolve-all-comments": _vm.resolveAllComments
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }