var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }, [_c('div', {
    staticClass: "py-3 px-4"
  }, [_c('div', {
    staticClass: "pb-5",
    class: !_vm.isPreview ? 'section__wrapper' : ''
  }, [_c('p', {
    staticClass: "company-title"
  }, [_vm._v("Details")]), _c('div', {
    staticClass: "company-report mx-3"
  }, [_c('p', {
    staticClass: "company-report__title mt-4"
  }, [_vm._v("Title of report")]), _c('p', {
    staticClass: "company-report__description"
  }, [_vm._v(_vm._s(_vm.report.reportInformation ? _vm.report.reportInformation.reportTitle : "N/A"))]), _c('p', {
    staticClass: "company-report__title mt-4"
  }, [_vm._v("Report ID")]), _c('p', {
    staticClass: "company-report__description"
  }, [_vm._v(_vm._s(_vm.report.reportInformation ? _vm.report.reportInformation.reportCode : "XXXXXX"))]), _c('div', {
    staticClass: "d-flex justify-content-between company-report__timeline mt-4"
  }, [_c('div', [_c('p', {
    staticClass: "company-report__title"
  }, [_vm._v("Reporting timeline (Start)")]), _c('p', {
    staticClass: "company-report__description"
  }, [_vm._v(_vm._s(_vm.report.reportInformation ? _vm.formatDate(_vm.report.reportInformation.reportingTimelineFrom) : ""))])]), _c('div', [_c('p', {
    staticClass: "company-report__title"
  }, [_vm._v("Reporting timeline (End)")]), _c('p', {
    staticClass: "company-report__description"
  }, [_vm._v(_vm._s(_vm.report.reportInformation ? _vm.formatDate(_vm.report.reportInformation.reportingTimelineTo) : ""))])])]), _c('p', {
    staticClass: "company-report__title mt-4"
  }, [_vm._v("Sectorial regulator")]), _c('p', {
    staticClass: "company-report__description"
  }, [_vm._v(_vm._s(_vm.report.reportInformation ? _vm.report.reportInformation.sectorialRegulator : "N/A"))]), _c('p', {
    staticClass: "company-report__title mt-4"
  }, [_vm._v("Other regulator(s)")]), _c('div', {
    staticClass: "border border-gray-light rounded-4 p-3 pb-5"
  }, _vm._l(_vm.report.reportInformation.otherRegulatorList, function (regulator, index) {
    return _c('span', {
      key: index,
      staticClass: "mr-3 company-report__regulator--text"
    }, [_vm._v(_vm._s(regulator || "N/A"))]);
  }), 0)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }