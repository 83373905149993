var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "report-comment d-flex flex-column",
    class: {
      'report-comment--editing': _vm.isEditing
    }
  }, [_c('span', {
    staticClass: "report-comment__header d-flex justify-content-between align-items-center"
  }, [_c('span', {
    staticClass: "report-comment__time text-black-70"
  }, [_vm._v(_vm._s(_vm.DateTime.fromISO(_vm.comment.createdOn).toFormat("hh:mm a")))]), _vm.canApproveOrDecline && !_vm.isCreator ? [_vm.isEditing ? [_c('ba-base-icon', {
    attrs: {
      "name": "ba-edit-pen",
      "height": "16px",
      "width": "16px"
    }
  })] : _c('div', {
    staticClass: "position-relative"
  }, [_c('BaBaseDropdownToggler', {
    nativeOn: {
      "click": function ($event) {
        return _vm.$emit('open-comment-options', _vm.comment.id);
      }
    }
  }), _c('BaBaseDropdown', {
    attrs: {
      "show": _vm.showCommentOptions,
      "width": "220px",
      "right": "0px",
      "top": "30px"
    },
    on: {
      "outside-clicked": function ($event) {
        return _vm.$emit('close-options');
      }
    }
  }, _vm._l(_vm.commentOptions, function (option) {
    return _c('BaBaseDropdownItem', {
      key: option.icon,
      attrs: {
        "icon": option.icon,
        "text": option.text
      },
      nativeOn: {
        "click": function ($event) {
          return option.action.apply(null, arguments);
        }
      }
    });
  }), 1)], 1)] : _vm.isCreator ? [_vm.comment.resolved ? _c('button', {
    staticClass: "ba-tooltip border-0 m-0 p-0 report-comment__button d-flex align-items-center justify-content-center",
    attrs: {
      "data-tooltip": "Unresolve"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('unresolve-comment', _vm.comment.id);
      }
    }
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-close-small",
      "height": "auto",
      "width": "auto"
    }
  })], 1) : _c('button', {
    staticClass: "ba-tooltip border-0 m-0 p-0 report-comment__button d-flex align-items-center justify-content-center",
    attrs: {
      "data-tooltip": "Resolve"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('resolve-comment', _vm.comment.id);
      }
    }
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-check-small",
      "height": "auto",
      "width": "auto"
    }
  })], 1)] : _vm._e()], 2), _c('span', {
    staticClass: "report-comment__body text-black-100"
  }, [_vm._v(_vm._s(_vm.comment.body))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }