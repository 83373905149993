
import Vue from "vue";
import { formatMoney, formatDate } from "@/utils/helpers";

export default Vue.extend({
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: () => ({}),
        },
        flaggedSections: {
            type: Object,
            default: () => {
                return {
                    auditor: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    registrar: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    boardConsultant: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    governanceConsultant: {
                        flagReason: null,
                        isFlagged: false,
                    },
                };
            },
        },
        isRegulatorValid: {
            type: Boolean,
            default: false,
        },
        isPreview: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        formatMoney,
        formatDate,
        sendFlaggedProps(description: string, comment: string) {
            this.$emit("clicked", { description, comment });
        },
        getUser(id: string) {
            return this.report?.users?.find((user: any) => user.userId === id);
        },
    },
});
