
import Vue from "vue";

export default Vue.extend({
    name: "ReportConfirmationModal",
    props: {
        isLoading: { type: Boolean, default: false },
        confirmationType: { type: String, required: true }, // 'approve-report', 'submit-report', 'resolve-comments'
    },
    data() {
        return {};
    },
    methods: {
        closeModal() {
            this.$bvModal.hide(`modal-report-confirmation-${this.confirmationType}`);
        },
    },
});
