var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }, [_c('div', {
    staticClass: "py-3 px-4"
  }, [_c('div', {
    staticClass: "pb-5",
    class: !_vm.isPreview ? 'section__wrapper' : ''
  }, [_c('p', {
    staticClass: "structure-title"
  }, [_vm._v("Governance Stakeholders")]), _c('div', {
    staticClass: "structure-table mx-3 mb-4"
  }, [_c('div', {
    staticClass: "structure-table__head d-flex justify-content-between"
  }, [_c('p', [_vm._v("External Auditor")]), _c('p', [_vm._v("Position")]), _c('p', [_vm._v("Date of appointment")])]), _c('div', {
    staticClass: "structure-table__body--editor"
  }, [_vm.report.auditors.length > 0 ? _c('div', _vm._l(_vm.report.auditors, function (auditor, ix) {
    return _c('div', {
      key: ix,
      staticClass: "d-flex justify-content-between"
    }, [_c('p', {
      staticClass: "structure-table__body--title mb-2"
    }, [_vm._v(_vm._s(auditor.name || "Not Provided"))]), _c('div', {
      staticClass: "badge-wrap",
      class: {
        'add-m': ix == 1
      }
    }, [_c('span', {
      staticClass: "blue-pill mb-2 text-center"
    }, [_vm._v("Auditor")])]), _c('p', {
      staticClass: "structure-table__body--title margin-left mb-0"
    }, [_vm._v(_vm._s(_vm.formatDate(auditor.dateOfAppointment) || "Not Provided"))])]);
  }), 0) : _c('div', {
    staticClass: "structure-table__body--title text-black-50"
  }, [_c('em', [_vm._v("None added")])]), _vm.flaggedSections.auditor.flagReason && _vm.isRegulatorValid ? _c('b-button', {
    staticClass: "text-black px-3 py-2 mt-2",
    staticStyle: {
      "font-size": "11px"
    },
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.sendFlaggedProps('View comment on External auditor', _vm.flaggedSections.auditor.flagReason);
      }
    }
  }, [_vm._v("View Comment")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "structure-table mx-3 mb-4"
  }, [_c('div', {
    staticClass: "structure-table__head d-flex justify-content-between"
  }, [_c('p', [_vm._v("Governance Evaluation Consultant")]), _c('p', [_vm._v("Position")]), _c('p', [_vm._v("Date of appointment")])]), _c('div', {
    staticClass: "structure-table__body--editor"
  }, [_vm.report.governanceConsultants.length > 0 ? _c('div', _vm._l(_vm.report.governanceConsultants, function (governanceConsultant, ix) {
    return _c('div', {
      key: ix,
      staticClass: "d-flex justify-content-between"
    }, [_c('p', {
      staticClass: "structure-table__body--title mb-0"
    }, [_vm._v(_vm._s(governanceConsultant.name || "Not Provided"))]), _c('div', {
      staticStyle: {
        "width": "0px"
      }
    }, [_c('span', {
      staticClass: "blue-pill mb-2 text-center"
    }, [_vm._v("Consultant")])]), _c('p', {
      staticClass: "structure-table__body--title mb-0 margin-left text-center"
    }, [_vm._v(_vm._s(_vm.formatDate(governanceConsultant.dateOfAppointment) || "Not Provided"))])]);
  }), 0) : _c('div', {
    staticClass: "structure-table__body--title text-black-50"
  }, [_c('em', [_vm._v("None added")])]), _vm.flaggedSections.governanceConsultant.flagReason && _vm.isRegulatorValid ? _c('b-button', {
    staticClass: "text-black px-3 py-2",
    staticStyle: {
      "font-size": "11px"
    },
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.sendFlaggedProps('View comment on External governance consultant', _vm.flaggedSections.governanceConsultant.flagReason);
      }
    }
  }, [_vm._v("View Comment")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "structure-table mx-3 mb-4"
  }, [_c('div', {
    staticClass: "structure-table__head d-flex justify-content-between"
  }, [_c('p', [_vm._v("Board Evaluation Consultant")]), _c('p', [_vm._v("Position")]), _c('p', [_vm._v("Date of appointment")])]), _c('div', {
    staticClass: "structure-table__body--editor"
  }, [_vm.report.boardConsultants.length > 0 ? _c('div', _vm._l(_vm.report.boardConsultants, function (boardConsultant, ix) {
    return _c('div', {
      key: ix,
      staticClass: "d-flex justify-content-between"
    }, [_c('p', {
      staticClass: "structure-table__body--title"
    }, [_vm._v(_vm._s(boardConsultant.name || "Not Provided"))]), _c('div', {
      staticStyle: {
        "width": "0px"
      }
    }, [_c('span', {
      staticClass: "blue-pill mb-2 text-center"
    }, [_vm._v("Consultant")])]), _c('p', {
      staticClass: "structure-table__body--title margin-left text-center"
    }, [_vm._v(_vm._s(_vm.formatDate(boardConsultant.dateOfAppointment) || "Not Provided"))])]);
  }), 0) : _c('div', {
    staticClass: "structure-table__body--title text-black-50"
  }, [_c('em', [_vm._v("None added")])]), _vm.flaggedSections.boardConsultant.flagReason && _vm.isRegulatorValid ? _c('b-button', {
    staticClass: "text-black px-3 py-2",
    staticStyle: {
      "font-size": "11px"
    },
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.sendFlaggedProps('View comment on External board consultant', _vm.flaggedSections.boardConsultant.flagReason);
      }
    }
  }, [_vm._v("View Comment")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "structure-table mx-3 mb-4"
  }, [_c('div', {
    staticClass: "structure-table__head d-flex justify-content-between"
  }, [_c('p', [_vm._v("Registrar")]), _c('p', [_vm._v("Position")]), _c('p', [_vm._v("Date of appointment")])]), _c('div', {
    staticClass: "structure-table__body--editor"
  }, [_vm.report.registrarCompanies.length > 0 ? _c('div', _vm._l(_vm.report.registrarCompanies, function (registrar, ix) {
    return _c('div', {
      key: ix,
      staticClass: "d-flex justify-content-between"
    }, [_c('p', {
      staticClass: "structure-table__body--title"
    }, [_vm._v(_vm._s(registrar.name || "Not Provided"))]), _c('div', {
      staticStyle: {
        "width": "0px"
      }
    }, [_c('span', {
      staticClass: "blue-pill mb-2 text-center"
    }, [_vm._v("Registrar")])]), _c('p', {
      staticClass: "structure-table__body--title margin-left text-center"
    }, [_vm._v(_vm._s(_vm.formatDate(registrar.dateOfAppointment) || "Not Provided"))])]);
  }), 0) : _c('div', {
    staticClass: "structure-table__body--title text-black-50"
  }, [_c('em', [_vm._v("None added")])]), _vm.flaggedSections.registrar.flagReason && _vm.isRegulatorValid ? _c('b-button', {
    staticClass: "text-black px-3 py-2",
    staticStyle: {
      "font-size": "11px"
    },
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.sendFlaggedProps('View comment on External registrar', _vm.flaggedSections.registrar.flagReason);
      }
    }
  }, [_vm._v("View Comment")]) : _vm._e()], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }