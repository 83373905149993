var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container",
    attrs: {
      "id": 'board-committee',
      "title": 'Board Committee',
      "description": `Learn more about this board committee`,
      "custom-content-class": "overflow-scroll selectable-modal__content"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-1"
  }, [_vm._v("Committee name")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.boardCommitteeInfo.name))]), _c('p', {
    staticClass: "committee__name mt-4 mb-1"
  }, [_vm._v("Committee description")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.boardCommitteeInfo.description))]), _c('p', {
    staticClass: "committee__name mt-4 mb-1"
  }, [_vm._v("Mandate summary")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.boardCommitteeInfo.mandateSummary))]), _c('p', {
    staticClass: "committee__name mt-4 mb-1"
  }, [_vm._v("Mandate document")]), _c('div', {
    staticClass: "border border-gray-20 rounded-4 p-3"
  }), _c('div', {
    staticClass: "structure-table mt-4"
  }, [_c('div', {
    staticClass: "structure-table__head d-flex justify-content-between"
  }, [_c('p', [_vm._v("Committee members")])]), _vm._l(_vm.boardCommitteeInfo.userIds, function (user, index) {
    return _c('div', {
      key: index,
      staticClass: "structure-table__body"
    }, [_c('p', {
      staticClass: "structure-table__body--title"
    }, [_vm._v(" " + _vm._s(_vm.getUser(user).firstName) + " " + _vm._s(_vm.getUser(user).lastName))]), _c('p', {
      staticClass: "structure-table__body--action pointer",
      on: {
        "click": function ($event) {
          return _vm.getCommitteeDetails(user);
        }
      }
    }, [_vm._v("View details")])]);
  })], 2)])], 1), _c('b-row', {
    staticClass: "mt-5"
  }, [_c('b-col', {
    staticClass: "d-flex flex-row justify-content-end align-items-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "text": "Close",
      "variant": "light"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('modal-board-committee');
      }
    }
  })], 1)], 1), _c('director-modal', {
    attrs: {
      "id": _vm.userId
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }