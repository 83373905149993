var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }, [_c('div', {
    staticClass: "py-3 px-4"
  }, [_c('div', {
    staticClass: "pb-5",
    class: !_vm.isPreview ? 'section__wrapper' : ''
  }, _vm._l(_vm.report.principles, function (principle, ix) {
    return _c('div', {
      key: ix
    }, [_c('p', {
      staticClass: "structure-title"
    }, [_vm._v(_vm._s(principle.name) + " : " + _vm._s(principle.title))]), _c('p', {
      staticClass: "principle-desc"
    }, [_vm._v(_vm._s(principle.description))]), _vm._l(principle.inputModels, function (inputModel, idx) {
      return _c('div', {
        key: idx,
        staticClass: "mb-4"
      }, [_c('question-output', {
        attrs: {
          "inputModel": inputModel,
          "principle": principle,
          "index": idx,
          "isRegulatorValid": _vm.isRegulatorValid
        },
        on: {
          "inputModel": _vm.sendFlaggedProps
        }
      })], 1);
    })], 2);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }